<template>
  <v-container id="printDiscounts" fluid tag="section">
    <alert-notification :message="alert" />
    <v-banner color="secondary" icon="mdi-firefox" dark outlined rounded class="mb-6 ma-auto" style="max-width: 500px;">
      <div>
        <h1>Utilisez Firefox pour l'impression</h1>
      </div>
    </v-banner>
    <v-card>
      <v-card-title>
        {{ $t('prospecting.discounts.subtitle') }}
        <v-spacer />
        <v-text-field v-model="table.search" append-icon="mdi-magnify" :label="$t('prospecting.discounts.table.search')" single-line hide-details />
        <v-spacer />
        <create-discount-form @success="refreshData" @fail="fail" />
        <print-discount-form :ids="(selected || []).map((discount) => discount.id)" @success="refreshData" @fail="fail" />
        <archive-discount-form :ids="(selected || []).map((discount) => discount.id)" @success="refreshData" @fail="fail" />
      </v-card-title>
      <v-data-table v-model="selected" :headers="table.headers" :options="table.options" :items="discounts" :search="table.search" :loading="loading" show-select>
        <template #[`item.quantity`]="{ item }">
          <span v-if="Array.isArray(item.codes) && item.codes.length !== item.quantity">{{ item.codes.length }} / {{ item.quantity }}</span>
          <span v-else>{{ item.quantity }}</span>
        </template>
        <template #[`item.toDate`]="{ item }">
          <span>{{ formatDate(item.toDate) }}</span>
        </template>
        <template #[`item.actions`]="{ item }">
          <print-discount-form :id="item.id" @success="refreshData" @fail="fail" />
          <archive-discount-form :id="item.id" @success="refreshData" @fail="fail" />
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import { DateTime } from 'luxon';
  import { handleRequestError } from '@/services/common/Http';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';
  import CreateDiscountForm from '@/views/forms/meta/prospecting/CreateDiscountForm';
  import PrintDiscountForm from '@/views/forms/meta/prospecting/PrintDiscountForm';
  import ArchiveDiscountForm from '@/views/forms/meta/prospecting/ArchiveDiscountForm';

  export default {
    name: 'AdminPrintDiscounts',
    components: { AlertNotification, ArchiveDiscountForm, CreateDiscountForm, PrintDiscountForm },
    data () {
      return {
        alert: '',
        loading: false,
        table: {
          headers: [
            {
              text: this.$i18n.t('prospecting.discounts.table.column.id'),
              align: 'center',
              filterable: false,
              sortable: true,
              value: 'id'
            },
            {
              text: this.$i18n.t('prospecting.discounts.table.column.name'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'name'
            },
            {
              text: this.$i18n.t('prospecting.discounts.table.column.description'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'description'
            },
            {
              text: this.$i18n.t('prospecting.discounts.table.column.quantity'),
              align: 'center',
              filterable: false,
              sortable: true,
              value: 'quantity'
            },
            {
              text: this.$i18n.t('prospecting.discounts.table.column.expiration'),
              align: 'center',
              filterable: false,
              sortable: true,
              value: 'toDate'
            },
            {
              text: '',
              align: 'center',
              filterable: false,
              sortable: false,
              value: 'actions'
            }
          ],
          options: {
            page: 1,
            itemsPerPage: -1,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            multiSort: false,
            mustSort: true
          },
          search: ''
        },
        selected: [],
        discounts: []
      };
    },
    mounted: async function () {
      await this.refreshData();
    },
    methods: {
      formatDate (date) {
        return DateTime.fromISO(date, { setZone: true }).toLocaleString({ locale: 'fr-fr' });
      },
      async refreshData () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'discounts/distribution');
          if (response.data !== undefined && response.data.discounts !== undefined) {
            this.discounts = response.data.discounts;
          }

          this.alert = '';
        } catch (error) {
          this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('admin.error.fail'));
        }
        this.loading = false;
      },
      async fail (error, message) {
        this.alert = await handleRequestError(error, this.$router, this.$i18n, message);
      }
    }
  };
</script>
