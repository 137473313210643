<template>
  <div>
    <v-btn small rounded color="primary" :loading="show || loading" :disabled="show || loading" @click.stop="show=true">
      <v-icon medium dark class="pr-2">
        mdi-label-percent
      </v-icon>
      {{ $t('prospecting.discounts.table.action.create.title') }}
    </v-btn>
    <v-dialog v-model="show" :persistent="false" :light="true" :fullscreen="true" :scrollable="false" overlay-color="tertiary" max-width="90%" class="pa-2 elevation-0" content-class="elevation-0">
      <base-material-card class="mt-6">
        <template #heading>
          <div class="display-2 font-weight-light text-uppercase">
            {{ $t('prospecting.discounts.table.action.create.title') }}
          </div>
          <div class="subtitle-1 font-weight-light">
            {{ $t('prospecting.discounts.table.action.create.subtitle') }}
          </div>
        </template>

        <v-form ref="form" v-model="valid" lazy-validation @keyup.native.enter="validate() && createDiscount()">
          <v-container class="create-discount" style="max-width: 1200px">
            <v-row>
              <v-col cols="12" sm="6" md="2">
                {{ $t('prospecting.discounts.form.field.target') }}
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-select v-model="target" item-value="id" item-text="name" :items="targets" :loading="loading"
                          required dense hide-details style="width:95%"
                />
              </v-col>
              <v-col cols="12" sm="6" md="2">
                {{ $t('prospecting.discounts.form.field.value') }}
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-select v-model="rawValue" item-value="id" item-text="name" :items="values" :loading="loading"
                          required dense hide-details style="width:95%" @change="updateValue()"
                />
              </v-col>
              <v-col cols="12" sm="6" md="2">
                {{ $t('prospecting.discounts.form.field.quantity') }}
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="quantity" type="number" min="1" max="2000" required dense solo hide-details
                              :label="$t('prospecting.discounts.form.field.quantity')" :clearable="true"
                />
              </v-col>
              <v-col cols="12" sm="6" md="2">
                {{ $t('prospecting.discounts.form.field.minimum') }}
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="minimum" type="number" min="0" max="100" required dense solo hide-details
                              :label="$t('prospecting.discounts.form.field.minimum')" :clearable="true"
                />
              </v-col>
              <v-col cols="12" sm="6" md="2">
                {{ $t('prospecting.discounts.form.field.usageLimit') }}
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="usageLimit" type="number" min="1" max="3" required dense solo hide-details
                              :label="$t('prospecting.discounts.form.field.usageLimit')" :clearable="true"
                />
              </v-col>
              <v-col cols="12" sm="6" md="2">
                {{ $t('prospecting.discounts.form.field.expirationInDays') }}
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="expirationInDays" type="number" min="1" max="150" required dense solo hide-details
                              :label="$t('prospecting.discounts.form.field.expirationInDays')" :clearable="true"
                />
              </v-col>
              <v-col cols="12" sm="6" md="2">
                {{ $t('prospecting.discounts.form.field.campaign.name') }}
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="campaign" type="text" :rules="campaignRules" :counter="campaignMaxLength" required dense solo
                              :label="$t('prospecting.discounts.form.field.campaign.name')" :clearable="true" @keyup="updateCampaignCode"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn color="close" @click.stop="show=false">
              {{ $t('prospecting.discounts.table.action.close.title') }}
            </v-btn>
            <v-btn :disabled="!valid || loading" :loading="loading" color="success" @click.stop="validate() && createDiscount()">
              {{ $t('prospecting.discounts.table.action.save.title') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </base-material-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'CreateDiscountForm',
    props: {},
    data () {
      return {
        show: false,
        valid: true,
        loading: false,
        target: 'restaurant',
        targets: [{ id: 'market', name: 'Marché' }, { id: 'restaurant', name: 'Restaurant' }],
        rawValue: '-5P',
        values: [
          { id: '-5P', name: '-5%' }, { id: '-10P', name: '-10%' }, { id: '-15P', name: '-15%' },
          { id: '-5E', name: '-5€' }, { id: 'freedelivery', name: 'Livraison Gratuite' }
        ],
        type: 'delivery',
        value: -100,
        valueType: 'percentage',
        quantity: 100,
        campaign: '',
        minimum: 11.9,
        usageLimit: 1,
        expirationInDays: 90,
        campaignMinLength: 0,
        campaignMaxLength: 3,
        campaignRules: [
          v => !v || (v && v.length >= this.campaignMinLength) || this.$t('prospecting.discounts.form.field.campaign.rule.minLength', { count: this.campaignMinLength }),
          v => !v || (v && v.length <= this.campaignMaxLength) || this.$t('prospecting.discounts.form.field.campaign.rule.maxLength', { count: this.campaignMaxLength })
        ]
      };
    },
    mounted: async function () {
      // TODO
    },
    methods: {
      updateValue () {
        switch (this.rawValue) {
          case '-5P':
            this.type = 'voucher';
            this.value = -5;
            this.valueType = 'percentage';
            break;
          case '-10P':
            this.type = 'voucher';
            this.value = -10;
            this.valueType = 'percentage';
            break;
          case '-15P':
            this.type = 'voucher';
            this.value = -15;
            this.valueType = 'percentage';
            break;
          case '-5E':
            this.type = 'voucher';
            this.value = -5;
            this.valueType = 'fixed_amount';
            break;
          case 'freedelivery':
            this.type = 'delivery';
            this.value = -100;
            this.valueType = 'percentage';
            break;
        }
      },
      updateCampaignCode () {
        this.campaign = this.campaign.toUpperCase();
        this.campaign = this.campaign.split('').filter((char) => ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'K', 'M', 'N', 'P', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'].includes(char)).join('');
      },
      async createDiscount () {
        this.loading = true;
        this.show = true;
        try {
          this.updateValue();

          await this.$http.post(process.env.VUE_APP_BASE_API_URL + 'discount', {
            target: this.target,
            type: this.type,
            value: Number(this.value),
            valueType: this.valueType,
            quantity: Number(this.quantity),
            minimum: Number(this.minimum),
            usageLimit: Number(this.usageLimit),
            expirationInDays: Number(this.expirationInDays),
            campaign: String(this.campaign)
          }, { timeout: 60000 });
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('admin.error.fail'));
        }
        this.loading = false;
        this.show = false;
      },
      validate () {
        return this.$refs.form.validate();
      }
    }
  };
</script>
