<template>
  <span>
    <v-icon v-if="id" medium color="secondary" :loading="loading" :disabled="loading" :title="$t('prospecting.discounts.table.action.print.title.single')" class="pr-3" @click="printDiscount">
      mdi-printer
    </v-icon>
    <v-btn v-if="!id" small rounded color="secondary" :loading="loading" :disabled="loading || (ids && ids.length === 0)" @click.stop="printDiscount">
      <v-icon medium dark class="pr-2">
        mdi-printer
      </v-icon>
      {{ $t('prospecting.discounts.table.action.print.title.multiple') }}
    </v-btn>
  </span>
</template>

<script>
  export default {
    name: 'PrintDiscountForm',
    props: {
      id: {
        type: Number
      },
      ids: {
        type: Array
      }
    },
    data () {
      return {
        loading: false
      };
    },
    methods: {
      async printDiscount () {
        this.loading = true;
        try {
          window.open(process.env.VUE_APP_BASE_APP_URL + 'discount.html?ids=' + (this.id ? [this.id] : this.ids).join(',')
                        + '&api=' + encodeURIComponent(String(process.env.VUE_APP_BASE_API_URL + 'discount/list')),
                      'printDiscount', 'location=yes,height=570,width=520,resizable,scrollbars,status');
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('admin.error.fail'));
        }
        this.loading = false;
      }
    }
  };
</script>
