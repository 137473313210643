<template>
  <span>
    <v-icon v-if="id" medium color="red" :loading="loading" :disabled="loading" :title="$t('prospecting.discounts.table.action.archive.title.single')" @click="archiveDiscount">
      mdi-archive
    </v-icon>
    <v-btn v-if="!id" small rounded color="red" :loading="loading" :disabled="loading || (ids && ids.length === 0)" @click.stop="archiveDiscount">
      <v-icon medium dark class="pr-2">
        mdi-archive
      </v-icon>
      {{ $t('prospecting.discounts.table.action.archive.title.multiple') }}
    </v-btn>
  </span>
</template>

<script>
  export default {
    name: 'ArchiveDiscountForm',
    props: {
      id: {
        type: Number
      },
      ids: {
        type: Array
      }
    },
    data () {
      return {
        loading: false
      };
    },
    methods: {
      async archiveDiscount () {
        if (confirm(this.$i18n.t('prospecting.discounts.table.action.archive.confirm'))) {
          this.loading = true;
          try {
            await this.$http.delete(process.env.VUE_APP_BASE_API_URL + 'discount', { data: { ids: (this.id ? [this.id] : this.ids).join(',') } });
            this.$emit('success');
          } catch (error) {
            this.$emit('fail', error, this.$i18n.t('admin.error.fail'));
          }
          this.loading = false;
        }
      }
    }
  };
</script>
